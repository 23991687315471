import * as F03_05P1 from "./03_05/Page1";

import * as F03_06P1 from "./03_06/Page1";
import * as F03_06P2 from "./03_06/Page2";
import * as F03_06P3 from "./03_06/Page3";
import * as F03_06P4 from "./03_06/Page4";
import * as F03_06P5 from "./03_06/Page5";

const Pages = {
    '03_05': {
        1: ['/facsimile/gaibach/03_05_Festprogramm/03_05_627_2021_0001.jpg', F03_05P1],
    },
    '03_06': {
        1: ['/facsimile/gaibach/03_06_Conversationsblatt/bsb10332176_00650.jpg', F03_06P1],
        2: ['/facsimile/gaibach/03_06_Conversationsblatt/bsb10332176_00651.jpg', F03_06P2],
        3: ['/facsimile/gaibach/03_06_Conversationsblatt/bsb10332176_00652.jpg', F03_06P3],
        4: ['/facsimile/gaibach/03_06_Conversationsblatt/bsb10332176_00653.jpg', F03_06P4],
        5: ['/facsimile/gaibach/03_06_Conversationsblatt/bsb10332176_00654.jpg', F03_06P5],
    },
};

export {Pages};