import * as React from 'react'
import * as Container from "../../container/container";

const Video = (key) => {
    return (
        <Container.Part background="beige" id="trailer" key={key}>
            <Container.Row>
                <div className="col-12">
                    <div className="inner-container">
                        <h2>Hoch hinaus mit Bürgermeister Heiko Bäuerlein.</h2>
                        <figure className="video">
                            <div className="yt-container yt-enabled">
                                <Container.Video url={"/videos/Filmmaterial/003_Gaibach/211011_Gaibach.mp4"}
                                                 poster={"/images/Videowallpaper/gaibach.jpg"}/>
                            </div>
                        </figure>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default Video
