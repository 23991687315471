import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>
                -gemeinem Mißcredit stehende bayerische Staatszeitung, in welcher man sich nicht entblödet,
                die offenbarsten Unwahrheiten dem Volke darzulegen, das Daseyn von Gesetzen, die nirgends
                bestehen, vorzuheucheln und die bestehenden auf die gröbste Art zu verdrehen, den Staatsangehörigen auf
                eine der Würde der Regierung zuwiderlaufende Weise aufgedrungen wird, macht man die Stimme der
                Opposition durch die gewaltthätigsten Maßregeln und durch die verfassungswidrige Unterdrückung
                der freisinnigen Journale verstummen, so daß die in der Verfassung enthaltene Garantie der freien
                Presse gegenwärtig nur als grausamer Hohn erscheint. Nur zu sehr bestätigt dich dieß beim
                Hinblicke auf das Verfahren der Regierung für den Untermainkreis und des Stadtcommissars zu Würzburg
                gegen die beiden Zeitschriften: der Volkstribun und das Volksblatt. Aus der Bestimmung des dritten
                constitutionellen Ediets §.2. daß politische Zeitungen der Zensur unterliegen sollen,
                wurde der Vorwand zu Gewaltschritten hervorgeholt. Die Regierung des Untermainkreises beschuldigte in
                einem Rescripte vom 30. März die Redakteurs des Volksblattes und des Volkstribuns,
                politische uncensirte Artikel aufgenommen zu haben, und drohte denselben, daß, wenn noch
                ein solcher Artikel erscheinen würde, künftig alle Blätter ohne Unterschied, ob politischen Inhaltes
                oder nicht, der Zensur vorgelegt werden müßten, ansonsten ein förmliches Verbot der Blätter
                erfolgen würde.
            </p>
            <p>
                Wir wollen davon absehen, daß in diesem Regierungs-Rescripte die Rechte der Journalisten
                schon dadurch verletzt wurden, daß der Begriff politischer Artikel (wohin doch offenbar blos
                jene gehörten, die auf das Verhältniß der Staaten gegeneinander Bezug haben) zu weit ausgedehnt,
                und auch auf die inneren Verhältnisse und Einrichtungen anderer, insbesondere deutscher Staaten
                ausgedehnt wurde, welche nicht zu berühren meistens unmöglich ist, wenn man die vaterländischen
                Verhältnisse beurteilen will. Auf das schreienste ist die Verfassung durch die gedachte
                Regierungsentschließung verletzt, weil dadurch eine Strafe der Censurumgehung, die durch kein
                Gesetz ausgesprochen ist, neu geschaffen wurde, und noch überdieß eine Strafe, welche in
                Entziehung konstitutioneller Rechte besteht, des Rechts, nichtpolitische Zeitschriften ohne Censur
                herauszugeben, ja sogar des Rechts, sie überhaupt herauszugeben. So hat man der Bestimmung der
                Verfassung Zit. VII. §.2. Hohn gesprochen, wonach sein neues Straf- oder sonstiges Gesetz ohne Zustimmung
                der Stände erlassen werden kann. Das beabsichtigte Attentat gegen die Verfassung wurde auch von der
                Regierung des Untermainkreises vollbracht, durch Rescript vom 10. April das Erscheinen des
                Volkstribuns bis auf Weitere verboten, und den Buchdruckern bei Vermeidung der Suspension, oder der
                Entziehung der Konzession untersagt, dieses Blatt zu drucken. Um das Maß der Willkühr und des
                Unrechts voll zu machen, wurde durch den Stadtkommissar das Verbot des Volkstribuns auf Alles, was von
                dessen Redakteur Widmann herausgegeben werden will, ausgedehnt, eine nicht periodische, deßhalb
                verfassungsmäßig censurfreie Zeitschrift, welche derselben herauszugeben beabsichtigt, gleichfall
                verboten, und den stämmlichen Buchdruckern Würzburgs untersagt, irgend etwas von Wittmann
                herrührendes zu denken. Für eine solche Verfahungsweise vermögen wir keine Worte zu finden.
                Früher würde man solche zu den unmöglichen Dingen in Bayern gezählt haben.
            </p>
            <p>
                Schon vorher war der Stadtkommissar zu Würzburg mit Wissen und Genehmigung der Kreisregierung gegen den
                Volkstribun sowohl als das Volksblatt faktisch gewaltsam eingeschritten, und dieß Verfahren wird
                nach nunmehriger Unterdrückung des Volkstribuns gegen das Volksblatt fortgesetzt. Es wird nämlich der
                Drucker gezwungen, jedes Blatt, ehe an den Redakteur oder sonst Jemand ein Exemplar verabfolgt wird,
                dem Stadtkommissar vorzulegen, der nun nach Gutdünken das Erscheinen erlaubt oder verbietet. Dieß
                Verfahren wird gewaltsam mittelst Strafandrohungen und Verhängungen gegen den Drucker durch die
                diezu gar nicht kompetente Kreisregierung und das Stadtkommissariat durch gesetzt. Auf
                diese Art wird durch den Stadtkommissar zum Hohn der durch die Verfassung garantierten Preßefreiheit
                für nicht politische Zeitblätter eine Art Censur ausgeübt, ein rechtwidriger Unfug noch schlimmer
                als Censur, weil er mit Gewaltthätigkeiten verbunden ist, weil es ganze Blätter unterdrückt,
                während die Censur blos die anstößigen Stel-
            </p>
        </>
    )
}

export default Page;