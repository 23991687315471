import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock3Beige = (key, ortName) => {
    return (
        <Container.Part background="beige" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Die Verfassung von 1818 – Freunde und Kritiker</h2>
                    <div className="inner-container">
                        <blockquote>
                            „Wir wollen uns innigst freuen im Vorgefühle des reichen Segens dieser inhaltsvollen, guten
                            Gabe. Zwar kein Sprung zum Ideale, […] unverkennbar jedoch ein sehr wesentlicher
                            Fortschritt, eine passende Übergangsstufe zum Höhern, Bessern, in sich tragend alle Elemente
                            zur Vollendung […]“
                        </blockquote>
                        <span className="author">
                            Wilhelm Joseph Behr über die Verfassung 1818
                            </span>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock3Beige
