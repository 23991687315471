import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>
                versuchte. – Wir wollen keine Beyspiele aus andern Kreisen vorführen; wir wollen uns beschränken
                auf das, was vor unsern, der Unterzeichneten Augen, was in unserer Nähe vorgefallen ist. – Wir wollen
                dabei nicht erwähnen alle einzelnen, einen bösen verfassungswidrigen Geis beurkundenden Handlungen; wir
                wollen uns beschränken auf die offenbarsten und grellsten Verletzungen der Verfassung. – Es ist ein
                trauriger Dienst, den uns hier unser Gedächtnis leisten muß; möge es der letzte der Art seyn . – Als wir die Bekanntmachung Eurer königlichen Majestät Regierung der Untermainkreises vom
                13. Februar d. J. lasen, worin der zu Zweybrücken entstandene Verein zur Unterstützung der freien
                Presse als verbrecherisch, gegen die Sicherheit der deutschen Staaten, insbesondere Bayerns, gerichtet
                bezeichnet, vor dem Beitritte gewarnt und die Einschreitung der Gerichte dagegen hervorgerufen wurde,
                glaubten wir nicht, daß solche Ansichten auch die Eurer Königlichen Majestät Staatsministeriums
                seyn könnten. Es wandten sich mehrere Bewohner Würzburgs, welche an dem genannten Verein Theil
                zunehmen gesonnen waren, beschwerend an das Staatsministerium, und zeigten, daß ein Verein
                zur Unterstützung der freien Presse, welche die bayerische Verfassung gewährt, solcher keineswegs
                wiederstrebend seyn, daß ein Verein, welcher nirgends ein Verbrechen, nirgends gewaltsame
                Maaßregeln, sondern blos Unterstützung der freien Presse und Weckung des Gemeingeistes
                zur Aufgabe hat, nichts Verbrecherisches, nicht Verbotene seyn könne, dass insbesondere die
                bestehenden Verordnungen gegen geheime Gesellschaften auf diesen öffentlich existierenden Verein keine
                Anwendung finden. – Das Staatsministerium wies die Beschwerde durch eine Entschließung zurück, worin
                mit sophistischen Drehungen die verbrecherische Tendenz des Vereins gezeigt werden sollte, und zu diesem
                Zwecke der Aufruf eines Journalisten zur Theinahme am Vereine mißbraucht und
                mißdeutet wurde, obgleich man dem Ministerium gezeigt hatte, daß dessen, wenn auch heftige,
                Worte den ihnen zur Last gelegten Sinn nicht haben könnten, daß es übrigens auf die Ansicht
                dieses Journalisten, der weder Stifter noch ein Vorstand des Preßvereins ist, gar nicht
                anzukommen habe.
            </p>
            <p>
                In demselben Geiste, wie die erwähnte Ministerialentschließung, war die inzwischen erschienene Ordonanz
                vom 1. März gegen die sogenannten politischen Vereine und der Commentar der bayerischen
                Staatszeitung zu dieser Ordonnanz abgefaßt. Dieselbe ist offenbar verfassungswidrig,
                insoferne durch sie die Theilnahme an dem Preßvereine und dem inzwischen zu Würzburg
                gebildeten Vereine zur Entschädigung des edlen Patrioten Frhrn. v. Closen [Freiherr von Closen] verboten
                werden will. Sie verstößt gegen die heiligsten Urrechte der Menschen, gegen die in der Verfassung
                zugesicherte Freiheit und Sicherheit der Person und des Eigenthums, gegen das Recht, seine
                geistigen und materiellen Kräfte einem erlaubten Zwecke zu widmen, und sich zu diesem Zwecke zu
                vereinigen, - eine Vereinigung, welche durch keines der bestehenden, blos gegen heimliche
                Verbindungen gerichteten Gesetze verboten wird, und durch ein neues Gesetz ohne Zustimmung der Stände
                des Reichs nach Zit. VII. §. 2. der Verfassungs-Urkunde nicht verboten werden kann. Der Commentar
                in Nro. 4. der bayerischen Staatszeitung zu dieser Ordonnanz ist der Art, daß seine Tendenz
                jeden Gutgesinnten empören muß. Es wird da der grosseste Despotismus unter dem
                Aushängeschilde der Verfassung gepredigt, unter Andern der unerhörte, die Personen, die Freiheit, das
                Eigentum der Staatsangehörigen der unbedingten Sklaverei preisgebende Grundsatz aufgestellt: daß
                jede Handlung verboten, die Regierung gegen jede Handlung einzuschreiten befugt sey, welche die
                Verfassung oder ein sonstiges Gesetz nicht ausdrücklich erlaube. Nach diesen in der Staatszeitung
                gepredigten Grundsätzen verfuhr auch die Regierung des Untermeinkreises mit Hilfe des stets
                bereitwilligen Werkzeuges der Willkühr, des Stadtkommissars Gessert zu Würzburg, als man gegen
                einen Verein einzuschreiten, und die Theilnahme daran zu verbieten versuchte, den Niemand, der
                nicht die grosseste Unwissenheit der bestehenden Gesetze beurkunden, oder solche auf die
                heilloseste Weise verdrehen will, als etwas Unerlaubtes zu bezeichnen wagen kann, - nämliche den Verein
                zur Entschädigung des Baron Closen. Ein würdiges Seitenstück zu den Verfolgungen patriotischer Vereine
                bildet das Verfahren gegen die freie Presse. Während die in all-
            </p>
        </>
    )
}

export default Page;