import * as React from 'react'
import * as Container from "../../container/container";
import {Pages} from "./facsimile";

const TextBlock1blue = (key) => {
    return (
        <Container.Part background="blue" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>„…einem Staate anzugehören,<br/> <span>&nbsp;</span> dessen Verfassung<br/>fest gegen äußere und
                        innere<br/> <span>&nbsp;</span>Angriffe steht…“</h2>
                    <div className="inner-container">
                        <p>
                            Aus Begeisterung für die bayerische Verfassung von 1818 ließ Franz Erwein Graf von Schönborn
                            bei seinem Schloss in Gaibach eine monumentale Säule errichten. 1828 wurde die von dem
                            Architekten Leo von Klenze entworfene Säule fertiggestellt. Die Einweihungsfeier fand in
                            Anwesenheit von König Ludwig I. statt. Auch in den folgenden Jahren richtete Schönborn in
                            Gaibach Verfassungsfeiern aus.
                        </p>
                        <p>
                            Mit den vom König erlassenen Verfassungen von 1808 und 1818 wandelte Bayern sich zur
                            konstitutionellen Monarchie. Die Verfassung war für ihre Zeit fortschrittlich. Sie enthielt
                            Grundrechte und wurde zum Ausgangspunkt für die Entwicklung des modernen Parlamentarismus in
                            Bayern. In der Bevölkerung wuchs der Wunsch nach mehr Mitbestimmungs- und Freiheitsrechten.
                            Nach der Julirevolution 1830 in Frankreich jedoch stieg beim König das Misstrauen gegen eine
                            erweiterte politische Beteiligung. 1832 wurden Forderungen nach liberalen und demokratischen
                            Umgestaltungen zum Thema verschiedener Verfassungsfeiern, in Gaibach fand am 27. Mai wieder
                            eine Feier mit mehreren Tausend Menschen statt.
                        </p>

                        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">

                            <p>
                                Beim Gaibacher Fest 1832 war der Würzburger Bürgermeister Wilhelm Joseph Behr eine
                                zentrale Figur. Er hielt eine Rede zum Wunsch nach einer Verfassungsreform im liberalen
                                Sinne. Von ihm ging auch die Initiative aus, sich entsprechend an den König zu wenden.
                                Nach dem Fest wurde Behr wegen Hochverrats zu Festungshaft verurteilt.
                            </p>
                            <p>
                                Das Gaibacher Fest entwickelte sich von einer Feier für die von oben verordnete
                                Verfassung von 1818 zu einem Symbol für das Ringen um erweiterte politische Teilhabe.
                                Die Bayerische Einigung e.V. & Bayerische Volksstiftung erinnert in Gaibach regelmäßig
                                an die Verfassungstraditionen Bayerns.
                            </p>
                            <br/>
                        </Container.More>
                        <br/>
                        <div className="center">
                            <figure className="lightbox">
                                <Container.Facsimile
                                    pages={Pages['03_05']}
                                    img="/images/Gaibach/gaibach_bild_0005.jpg"
                                    alttext="Festprogramm für das Gaibacher Fest, 1832. Klick öffnet das Faksimile.">
                                    <figcaption>Festprogramm für das Gaibacher Fest, 1832
                                        <span className="description">
                                        Die ersten Verfassungsfeiern wurden von Graf Schönborn ausgerichtet. 1832
                                        kümmerte sich ein Festausschuss darum, während er selbst nur Gastgeber war. Das
                                        Programm glich dem der Vorjahre: Gottesdienst, Festmahl, Reden, Salutschüsse
                                        und Feuerwerk.
                                    </span>
                                        <span className="copy">Abb. Staatsarchiv Würzburg, Archiv der Grafen von Schönborn,
                                        Korrespondenz Franz Erwein, Fasz. 151</span>
                                    </figcaption>
                                </Container.Facsimile>
                            </figure>
                        </div>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock1blue
