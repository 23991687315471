import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock5Parallax = (key) => {
    return (
        <Container.Part background="bg-img parallax-bg parallax-gaibach" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox karte">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Gaibach/Grafik_Gaibach-Verfassung-1818.jpg"}
                           data-glightbox="title: Schaubild zur Bayerischen Verfassung von 1818; description: .custom-desc1_11; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Gaibach/Grafik_Gaibach-Verfassung-1818.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Schaubild zur Bayerischen Verfassung von 1818"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_11">
                            <p className="copy">
                                Die bayerische Verfassung von 1818
                            </p>
                        </div>
                        <figcaption>
                            Die bayerische Verfassung von 1818
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Gaibach/gaibach_bild_0010.jpg"}
                           data-glightbox="title: Entwurf der Konstitutionssäule, 1830; description: .custom-desc1_9; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Gaibach/gaibach_bild_0010.jpg" width={335} layout="fixed" placeholder="none" alt="Entwurf der Konstitutionssäule, 1830"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_9">
                            <p>
                                Der Entwurf zeigt schon die endgültige Form der Säule mit dem Leuchter an der Spitze.
                                Die Zeichnung am Blattrand zeigt die Treppe im Inneren der Säule.
                            </p>
                            <p className="copy">Lithografie von Carl Heinzmann
                                Abb. Bayerische Staatsbibliothek München, Res/2 Bavar. 477-1/8, Teilband 2
                            </p>
                        </div>
                        <figcaption>
                            Entwurf der Konstitutionssäule, 1830
                            <span className="description">
                                Der Entwurf zeigt schon die endgültige Form der Säule mit dem Leuchter an der Spitze.
                                Die Zeichnung am Blattrand zeigt die Treppe im Inneren der Säule.
                            </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock5Parallax
