import * as React from 'react'
import * as Container from "../../container/container";
import {Pages} from "../gaibach/facsimile";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock2White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Gaibach/gaibach_bild_0003.jpg"}
                           data-glightbox="title: Ansicht von Gaibach Richtung Vogelsburg, im Vordergrund die Konstitutionssäule, um 1828–1854; description: .custom-desc1_3; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Gaibach/gaibach_bild_0003.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Ansicht von Gaibach Richtung Vogelsburg, im Vordergrund die Konstitutionssäule, um 1828–1854"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_3">
                            <p>
                                Die Säule hat eine Höhe von gut dreißig Metern. Ihre Spitze bildet ein Leuchter aus
                                Bronze.
                            </p>
                            <p className="copy">
                                Lithografie von Johann Baptist Scheiner <br/>
                                Abb. Museum für Franken – Staatliches Museum für Kunst- und
                                Kulturgeschichte in Würzburg, Leihgabe aus Privatbesitz im
                                Museum für Franken in Würzburg
                            </p>
                        </div>
                        <figcaption>
                            Ansicht von Gaibach Richtung Vogelsburg, im Vordergrund die Konstitutionssäule, um 1828–1854
                            <span className="description">
                                Die Säule hat eine Höhe von gut dreißig Metern. Ihre Spitze bildet ein Leuchter aus Bronze.
                            </span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <Container.Facsimile
                            pages={Pages['03_06']}
                            img="/images/Gaibach/gaibach_bild_0006.jpg"
                            alttext="Münchener Conversations-Blatt, 7. Juni 1832. Klick öffnet das Faksimile.">
                            <figcaption>
                                Münchener Conversations-Blatt, 7. Juni 1832
                                <span className="description">
                                Trotz Zensurmaßnahmen druckte die Presse Auszüge aus den beim Gaibacher Fest gehaltenen
                                Reden und die dort verfasste Adresse an den König. <br/>
                                <span className="copy">Abb. Bayerische Staatsbibliothek München, 4 Bavar. 470-1832,1, 4. Jg</span>
                                </span>
                            </figcaption>
                        </Container.Facsimile>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2White
