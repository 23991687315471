import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock4White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Wilhelm Joseph Behr <br/> (1775–1851)</h2>
                    <p>
                        Behr wurde 1818 als Vertreter der Universität Würzburg in die bayerische Ständeversammlung
                        gewählt, wo er zu einem führenden Kopf der liberalen Gruppierung wurde.
                        1821 wurde Behr Bürgermeister von Würzburg, was die Regierung zum Anlass nahm, ihm Professur und
                        Abgeordnetenmandat zu entziehen. Für seine Rede beim Gaibacher Fest 1832 wurde er zu einer
                        mehrjährigen Haftstrafe verurteilt. Begnadigungsversuche wurden abgelehnt, erst 1847 wurde der
                        Rest der Strafe erlassen. 1848 wurde er in die deutsche Nationalversammlung in der Frankfurter
                        Paulskirche gewählt, wo er jedoch aus Altersgründen nur noch kurz wirken konnte.
                    </p>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Gaibach/gaibach_bild_0007.jpg"}
                           data-glightbox="description: .custom-desc1_6; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Gaibach/gaibach_bild_0007.jpg"  width={335} layout="fixed" placeholder="none" alt="Wilhelm Joseph Behr, 1819"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_6">
                            <h2>Wilhelm Joseph Behr, 1819</h2>
                            <p className="copy">
                                Gemälde von Johann Georg Hirschmann
                                Abb. Museum für Franken – Staatliches Museum für Kunst- und Kulturgeschichte in
                                Würzburg, Leihgabe der Stadt Würzburg im Museum für Franken in Würzburg
                            </p>
                        </div>
                        <figcaption>
                            Wilhelm Joseph Behr, 1819
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Gaibach/gaibach_bild_0008.jpg"}
                           data-glightbox="title: Wilhelm Joseph Behr, „Bedürfnisse und Wünsche der Bayern, begründet durch freimüthige Reflexionen über die Verfassung, die Gesetzgebung und Verwaltung des bayerischen Staates“, 1830; description: .custom-desc1_7; descPosition: bottom;">
                            <StaticImage src="../../../../static/images/Gaibach/gaibach_bild_0008.jpg" width={335} layout="fixed" placeholder="none"
                                 alt="Wilhelm Joseph Behr, „Bedürfnisse und Wünsche der Bayern, begründet durch freimüthige Reflexionen über die Verfassung, die Gesetzgebung und Verwaltung des bayerischen Staates“, 1830"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_7">
                            <p>
                                Vor der Ständeversammlung 1830/31 publizierte Behr eine Reformschrift, auf deren
                                Anliegen er sich auch in Gaibach bezog. Im Zentrum stand die Forderung nach einer
                                Umwandlung der bayerischen Ständeversammlung in eine Volksrepräsentation. Das Parlament
                                sollte auf Basis des gleichen Wahlrechts von allen aktiven Staatsbürgern gewählt werden.
                            </p>
                            <p className="copy">Abb. Staatliche Bibliothek Passau, S nv/Mkc 66</p>
                        </div>
                        <figcaption>
                            Wilhelm Joseph Behr, „Bedürfnisse und Wünsche der Bayern, begründet durch freimüthige
                            Reflexionen über die Verfassung, die Gesetzgebung und Verwaltung des bayerischen Staates“,
                            1830
                            <span className="description">
                                Vor der Ständeversammlung 1830/31 publizierte Behr eine Reformschrift, auf deren
                                Anliegen er sich auch in Gaibach bezog. Im Zentrum stand die Forderung nach einer
                                Umwandlung der bayerischen Ständeversammlung in eine Volksrepräsentation. Das Parlament
                                sollte auf Basis des gleichen Wahlrechts von allen aktiven Staatsbürgern gewählt werden.
                            </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock4White
