import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Novel = (key) => {
    return (
        <Container.Fullwidth key={key.toString() + 'slider'}>
            <Container.Novel id={key} sliderTitleId="nodelTitle">
                <div className="bg-grey">
                    <p>1828 | 1832 Gaibach</p>
                    <h2>Am Vormittag des 27. Mai 1832 im Gaibacher Schlosspark …</h2>
                    <StaticImage src="../../../../static/images/Gaibach/Novel/Szene_1_Bild_1.png" placeholder="none" alt=""/>
                </div>
                <div className="bg-grey">
                    <h2>Mittags in einem Gasthaus ganz in der Nähe …</h2>
                    <StaticImage src="../../../../static/images/Gaibach/Novel/Szene_2_Bild_1.png" placeholder="none" alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Gaibach/Novel/Szene_2_Bild_2.png" placeholder="none" alt=""/>
                <div className="bg-grey">
                    <h2>Wieder zurück beim Fest …</h2>
                    <StaticImage src="../../../../static/images/Gaibach/Novel/Szene_3_Bild_1.png" placeholder="none" alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Gaibach/Novel/Szene_3_Bild_2.png" placeholder="none" alt=""/>
            </Container.Novel>
        </Container.Fullwidth>
    )
}

export default Novel

