import * as React from 'react'

const Page = () => {
    return (
        <>
            <h2>Transkription zu:<br/>Festprogramm für das Gaibacher Fest, 1832</h2>
                <p>Programm über die Feier des Verfassungsfestes in Gaibach am<br/>27. Mai 1832</p>
                <p>
                        1. Nach dem um 10 Uhr beginnenden Frühgottesdienste, bei welchem die Musik des Würzburger
                        Landwehr-Regiments spielt, werden sich die Theilnehmer am Fest auf dem Platze vor der Kirche
                        und dem Schlosse versammeln, und in zwanglosen Reihen bei dem Spiele zweier Musikchöre unter
                        Absingen des Liedes „Brüder laßt uns geh'n zusammen, in des Maies Blüthenhaine“ nach der Säule
                        begeben.
                </p>
                <p>
                        2. Bei der Ankunft daselbst werden Geschützessalven den Anfang des Festes bezeichnen.
                </p>
                <p>
                        3. An der Säule werden von einem Mitglieder des Ausschusses einige Worte zur Einleitung und
                        über die Bedeutung des Festes gesprochen werden, worauf den übrigen Anwesenden Veranlassung
                        gegeben ist, auch ihre Gefühle, Wünsche oder Anträge darzulegen. Nach geendeten Reden wird ein
                        für diesen Tag gedichtetes Festlied unter Musikbegleitung abgesungen. – Fünf und zwanzig
                        Kanonenschüsse verkünden diesen Moment auch den entfernten Freunden der Verfassung.
                </p>
                <p>
                        4. Bei dem sodann stattfindenden Mittagessen wird der Toast auf die Verfassung ausgebracht,
                        durch fünfzig Kanonenschüsse gefeiert.
                </p>
                <p>
                        5. Nachmittag wird man sich dem geselligen Vergnügen und der freundschaftlichen Unterhaltung
                        hingeben; die auf der Schönbornshöhe aufgestellten Musikchöre, sowie Produktionen geübter
                        Freunde des Gesangs werden den Genuß vervielfältigen und erhöhen.
                </p>
                <p>
                        6. Um fünf Uhr rufen fünf Kanonenschüsse die Freunde der Verfassung zur Säule, um sich dort über
                        zweckmäßige Feier dieses Festes für das folgende Jahr zu besprechen, und etwa einen Ausschuß
                        hierfür, gewählt aus verschiedenen Orten des Kreises, zu ernennen, damit das Fest dergestallt
                        immer ausgebreiteter und volksthümlicher werde.
                </p>
                <p>
                        7. Bei angehender Dunkelheit wird ein Feuerwerk abgebrannt, dessen Anfang 5 Kanonenschüsse
                        verkünden.
                </p>
                <p>
                        Gesang, Musik und Tanz beschließen den festlichen Tag.
                </p>
        </>
    )
}

export default Page;