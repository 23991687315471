import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>
                -len streicht. Welchen Erfolg können sich die gekränkten Journalisten und Gewerksleute von ihrem
                gegen dieß Verfahren ergriffenen Rekurs an das Staatsministerium versprechen, - an ein Ministerium,
                welches unterm 31. März den das Erscheinen zweier bayerischen Blätter unter andern verbietenden
                Bundestagsbeschluß bekannt gemacht, und sich dabei gerühmt hat, wie es dem Wissen des Bundestags,
                ohne den Beschluß buchstäblich zu vollziehen, hinsichtlich dieser zwei bayer. Journale bereits
                nachgekommen sey, welches das Erscheinen einer ausländischen, dem Bundestag mißliebigen
                Zeitschrift untersagt, und dadurch das dritte konstitutionelle Edikt verletzt hat, das nirgends das Verbot
                einer im Aus- oder Inlande erscheinenden Schrift zulaßt, vielmehr den Buchhändlern und sonstigen
                Gewerbsleuten vollkommen freien Verkehr zusichert! Nicht bloß die Presse selbst wird verfolgt, sondern
                auch an der Person des Redakteurs Unrecht und Willkühr verübt. So wurde durch
                Regierungsbeschließung v. 9. April dem Vorstande des Landgerichts Würzburg e.d.M. aufgegeben, den
                Rechtspraktikanten Widmann aus seinem Geschäftslokale zu entfernen, indem die dich durch administrative
                Rücksichten veranlaßt gefunden habe, ihm die Praxis bei diesem Gerichte nicht länger zu
                gestatten.
            </p>
            <p>
                Widmann ist ein in moralischer und intellektueller Hinsicht ausgezeichneter Staatsdienstaspirant,
                untadelhaft im Geschäfts- und Privatleben. Kein rechtlicher Grund der Entfernung von der Praxis ist
                denkbar. Blos kleinliche Rachsucht wurde gegen ihn verübt; denn er war der Redakteurs des nun
                unterdrückten Zeitblattes: der „Volkstribun.“
            </p>
            <p>
                Es sind durch dieses Verfahren nicht nur die konstitutionellen Rechte des Praktikanten Widmann verletzt,
                sondern auch in die Rechte des Landgerichtsvorstandes wurde auf verfassungswidrige Weise eingegriffen.
                Man hat ohne gesetzliche Ursache gegen die Bestimmung der Verfassungsurkunde Titl. I. §. 8. Widmann
                verfolgt und in seine Rechte eingegriffen. Man hat ihn durch die Entfernung von der gerichtlichen Praxis,
                welche die Bedingung einer künftigen Anstellung ist, im voraus einer solchen für unfähig erklärt,
                da doch jeder Bayer ohne Unterschied gleichen Anspruch auf die Berufung im Staatsdienste haben soll.
                (Eingang der Verfassungsurkunde und Titl. IV. §. 4.) Man hat in den Privatvertrag zwischen dem
                Landesgerichtvorstande und Widmann störend eingegriffen, und das Recht des Erstern, seine
                Praktikanten, für welche er verantwortlich sein muß, zu wählen, gekränkt. Tief, ja sehr tief ist
                das Ansehen der Staatsregierung durch solche Handlungen erschüttert, das Vertrauen auf ihren loyalen,
                konstitutionellen Sinn ganz untergraben worden. Werden es Eure köngl. Maj. glauben, daß
                Einladungen zur Theilnahme an einer veranstalteten Feier der Verfassung von Staatsdienern aus
                dem Grunde abgelehnt worden sind, um kein Mißfallen bei der Regierung zu erwecken? So weit ist es
                in Bayern gekommen, dass der Glaube an ein die Gesinnungen erforschendes und Reden belauschendes, Treue
                und Glauben vergiftendes Spioniersystem der Regierung allgemein ist, - ein Glaube, welchen so manche
                Erscheinungen rechtfertigen.
            </p>
            <p>
                Täglich unerträglicher wird der gegenwärtige Zustand der Dinge. Alle Achtung vor dem Gesetz muß
                schwinden, allgemeine Demoralisation eintreten, wenn Staatsbeamte das Gesetz so frech verhöhnen, so
                frech die Rechte der Staatsbürger mit Füßen treten dürfen. Noch manche Zeit vergeht, bis eine neue
                Versammlung der Stände des Königreichs den Beschwerden über Verletzungen der Verfassung die Bahn öffnet.
                Soll bis dorthin dieser Stand der Dinge bleiben? Soll sich täglich der Stoff zu Beschwerden häufen?
            </p>
            <p>
                Dieß kann, dieß wird nicht seyn! Dessen sind uns die Gesinnungen eines Regenten
                Bürge, der öffentlich seine Abscheu vor despotischen Regierungen ausgesprochen, der öffentlich
                versichert hat: so heilig wie er, achte Niemand die Verfassung!
            </p>
            <p>
                Wir sehen einer Zeit stürmischer Ereignisse entgegen, wo es Roth thut, daß Regent und Volk
                durch die Bande des Vertrauens und des Gesetzes sich eng aneinander schließen, um kräftig durch
                diese Verbindung stehen zu können. Deßhalb haben wir unsere Beschwerde unmittelbar Eurer Maj.
                vorgelegt; möge ein Wort Allerhöchstderselben das verhöhnte und mißhandelte Gesetz wieder zu
                Leben und Kraft bringen!
            </p>
            <p>
                In allertiefster Ehrfurcht<br/>Eurer Königlichen Majestät<br/>Allerunterhändigst-treugehorsamste<br/>
                Würzburg und Gaibach, den 27. Mai 1832
            </p>
        </>
    )
}

export default Page;