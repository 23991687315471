import * as React from 'react'

const Page = () => {
    return (
        <>
            <h2>Transkription zu:<br/>Münchener Conversations-Blatt, 7. Juni 1832</h2>
            <p>
                Münchener – Conversations – Blatt<br/>Mitgabe zum Bauer’schen Beobachter (Zensurfrei.)
            </p>
            <p>
                München	Nro. 159 Donnerstag, den 7. Juni 1832.
            </p>
            <p>
                Bei dem Gaibacher Constitutionsfeste wurde folgende Staatsbürger-Addresse an Se. Maj. den König
                unterzeichnet:<br/><br/>„Allerdurchlauchtigster, Großmächtigster König!<br/>
                Allergnädigster König und Herr!
            </p>
            <p>
                Es ist heute zum vierzehnten Male jener Tag wiedergekehrt, an dem Ihr unsterblicher Vater, der erste
                unter den deutschen Fürsten, die Mündigkeit seiner Nation feierlich anerkannt, und durch Verkündigung
                des Freiheitsbriefes sich und dem Bayernvolke den vorzüglichsten Rang unter den deutschen Regenten und
                Völkerstämmen erworben hat.
            </p>
            <p>
                Sechs Jahre sind verflossen, seitdem wir den bayerischen Thron einen Prinzen besteigen sahen, dazu
                geschaffen, und durch das allgemeine Vertrauen dazu berufen, von der Schöpfung seines unvergeßlichen
                Vaters der Erhalter und Fortbilder zu seyn, und das in die magna charta niedergelegte
                heilige Vermächtniß: „nicht zu hindern das Fortschreiten zum Bessern,“ in Erfüllung zu bringen.
                – Der Geburtstag dieser magna charta hat bisher jährlich alle gutgesinnten Bayern zur festlichen
                Begehung anrufend, die Anhänglichkeit an Vaterland, König und Verfassung, das Dankgefühl für deren
                Geber geweckt und gestärkt, und das freudige Gefühl hervorgerufen, einem Staate anzugehören, dessen
                Verfassung fest gegen äußere und innere Angriffe steht. Wir begehen diese Feier heute mit denselben
                glühenden Dankgefühlen für den verewigten Schöpfer der Verfassung, mit derselben Liebe für unsern
                Regenten, mit derselben Anhänglichkeit an die Verfassung; aber auch mit ernsten und schmerzlichen
                Betrachtungen über das, was wir alles seit kurzem erleben mußten, mit düstern Erwartungen der
                Dinge, die da noch kommen sollen. Könnte unser festes Vertrauen auf Eurer königl. Majestät
                consitutionelle Gesinnungen, auf Ihre vor den Augen der Welt gegebenen feierlichen Versicherungen,
                die geringste Erschütterung erleiden, so würde, solche zu bewirken, nichts geeigneter denn, als die
                Handlungen, welche sich bayerische Staatsbeamten erlauben, dir durch ihre Stellung zunächst zur
                Aufrechterhaltung der Verfassung berufen sind.
            </p>
            <p>
                Will man den zahllosen Schritten der Willkühr, welche seit kurzem geschehen, Konsequenz und Zweck
                unterlegen, so ist es unmöglich, ein anderes Ziel zu erkennen, als das der gänzlichen Vernichtung der
                Verfassung. Jede Verfassung ist blos Schein, nicht Wahrheit, blos [sich!] ein unwürdiges
                Spielwerk, wenn sie nicht durch die Anhänglichkeit der Nation an sie, durch den Gemeingeist der
                Staatsbürger, wenn die nicht durch die freie Presse geschützt wird. Diese Anhänglichkeit an die
                Verfassung, allen Gemeingeist zu tödten, und die freie Presse zu vernichten, ist die Aufgabe,
                welche man sich gesetzt zu haben scheint.
            </p>
            <p>
                Deshalb werden die erlaubten Handlungen verboten, welche den Gemeingeist wecken könnten; es werden
                Vereine als unerlaubt, ja sogar verbrecherisch hingestellt, weil sie den Patriotismus und den Sinn
                fürs öffentliche Leben zu befördern geeignet sind. Deshalb wird die freie Presse auf die brutale Weise
                unterdrückt, die rechtswidrigste Verfolgung gegen Journalisten verübt. – Unmöglich ist es, die große
                Reihe der schreienden Verfassungs-Verletzungen, welche in diesem Geiste in Bayern bisher verübt wurden,
                und täglich verübt werden, aufzuzählen; sie ist zu groß für die Beschränktheit des menschlichen
                Gedächtnisses. – Wir wollen nicht wiederholen die Klagen der Bewohner des Rheinkreises; sie haben laut
                genug durch Deutschland gehallt. – Wir wollen nicht erwähnen die gewaltsame Vernichtung der freien
                Presse daselbst, nicht erwähnen, wie man vor kurzem sogar eine Gegend in den Belagerungsstand zu setzen,
                eine angeordnete Feier der Verfassung zu verbieten
            </p>
        </>
    )
}

export default Page;