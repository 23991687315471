import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {
    return (
        <Container.HeaderPart background={ortName} key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1> 1828/1832<br/> Gaibach<br/><br/></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Mitbestimmung</li>
                                <li>Verfassung</li>
                                <li>Meinungs- und Pressefreiheit</li>
                            </ul>
                            <p> An der Konstitutionssäule in Gaibach wird seit 1828 an die bayerische Verfassung von
                                1818 erinnert.</p>
                            <p> Beim Gaibacher Fest 1832 wurden Rufe nach einer Reform der Verfassung, nach mehr
                                Volksbeteiligung und Meinungsfreiheit laut.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Gaibach/gaibach_bild_0002.jpg"}
                                   data-glightbox="title: Konstitutionssäule in Gaibach; description: .custom-desc1_1; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Gaibach/gaibach_bild_0002.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Konstitutionssäule in Gaibach"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_1">
                                    <p>
                                        Die Säule trägt die Inschrift: „Der Verfassung Bayerns, ihrem Geber Max Joseph,
                                        ihrem Erhalter Ludwig zum Denkmale“.
                                    </p>
                                    <p className="copy">Abb. Bildarchiv Bayerischer Landtag</p>
                                </div>
                                <figcaption>
                                    Konstitutionssäule in Gaibach
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Gaibach/gaibach_bild_0004.jpg"}
                                   data-glightbox="description: .custom-desc2_1; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Gaibach/gaibach_bild_0004.jpg" width={335} layout="fixed" placeholder="none"
                                         alt="Wilhelm Joseph Behr (1775–1851)"/>
                                </a>
                                <div className="glightbox-desc custom-desc2_1">
                                    <h2>Wilhelm Joseph Behr (1775–1851)</h2>
                                    <p>
                                        Wilhelm Joseph Behr war ab 1799
                                        Professor für Staatsrecht an der
                                        Universität Würzburg. Als Abgeordneter beim ersten Landtag 1819
                                        und ab 1821 als Bürgermeister von
                                        Würzburg gehörte er der liberalen
                                        Gruppierung an. Daher wurde
                                        er von der Regierung überwacht.
                                    </p>
                                    <p
                                        className="copy">Abb. Bayerische Staatsbibliothek München / Bildarchiv,
                                        port-016823</p>
                                </div>
                                <figcaption>
                                    Wilhelm Joseph Behr (1775–1851)
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
