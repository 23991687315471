import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const TextBlock5More = (key) => {
    return (
        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen" key={key}>
            <Container.Part background="beige" key={key}>
                <Container.Row>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Gaibach/gaibach_bild_0009.jpg"}
                               data-glightbox="description: .custom-desc1_8; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Gaibach/gaibach_bild_0009.jpg" width={335} layout="fixed" placeholder="none" alt="Franz Erwein Graf von Schönborn, 1816"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_8">
                                <h2>Franz Erwein Graf von Schönborn, 1816</h2>
                                <p className="copy">
                                    Lithografie von M. Baumann, nach einem Ölgemälde Joseph Karl Stielers
                                    Abb. Museum für Franken – Staatliches Museum für Kunst- und Kulturgeschichte in
                                    Würzburg, Leihgabe der Stadt Würzburg im Museum für Franken in Würzburg
                                </p>
                            </div>
                            <figcaption>
                                Franz Erwein Graf von Schönborn, 1816
                            </figcaption>
                        </figure>
                    </div>
                    <div className="col-6">
                        <h2>Franz Erwein Graf von Schönborn <br/> (1776–1840)</h2>
                        <p>
                            Schönborn begeisterte sich als Politiker für die Verfassung und war in regem Austausch mit
                            dem Kronprinzen Ludwig, mit dem er auch die Liebe zur Kunst teilte. Noch 1831 zeigte sich
                            das gute Einvernehmen zwischen den beiden: der nunmehrige König Ludwig I. sandte Schönborn
                            einen Konstitutionstaler mit den Worten: „Ein inniger Verfassungsfreund schickt ihn einem
                            anderen.“ Als Reichsrat wusste Schönborn aber auch von den wachsenden Differenzen zwischen
                            der liberalen Opposition und dem König. Man kann davon ausgehen, dass er sich deshalb bei
                            der Verfassungsfeier 1832 zurückhielt und die Vorbereitungen dem Festausschuss überließ.
                        </p>
                    </div>
                </Container.Row>
            </Container.Part>
            <Container.Part background="white" key={key}>
                <Container.Row>
                    <div className="col-6">
                        <h2>Franz Ludwig von Hornthal <br/> (1763-1833)</h2>
                        <p>
                            Mit Hornthal trat ein weiterer führender Kopf der fränkischen liberalen Bewegung als Redner
                            beim Gaibacher Fest auf. In seinen Ausführungen würdigte er die Errungenschaften der
                            Verfassung, verwies aber auch auf Bedrohungen für diese.<br/>
                            Hornthal war ab 1806 bayerischer Justizrat, von 1818 bis 1821 erster Bamberger Bürgermeister
                            und zwischen 1819 und 1825 Abgeordneter in der bayerischen Ständeversammlung. Als Teil der
                            liberalen Gruppierung war er mit seinen Veröffentlichungen auch von der Verschärfung der
                            Pressezensur unter Ludwig I. betroffen. Aufsehen erregte sein abgewiesener Antrag, das
                            Militär auf die Verfassung statt auf den König zu vereidigen.
                        </p>
                    </div>
                    <div className="col-6">
                        <figure className="lightbox">
                            <a className="glightbox" data-gallery="gallery2"
                               href={"/images/Gaibach/gaibach_bild_0011.jpg"}
                               data-glightbox="description: .custom-desc1_10; descPosition: bottom;">
                                <StaticImage src="../../../../static/images/Gaibach/gaibach_bild_0011.jpg" width={335} layout="fixed" placeholder="none" alt="Franz Ludwig von Hornthal, 1822"/>
                            </a>
                            <div className="glightbox-desc custom-desc1_10">
                                <h2>Franz Ludwig von Hornthal, 1822</h2>
                                <p className="copy">
                                    Lithografie von Johann Waldherr
                                    Abb. Stadtarchiv München, Historischer Verein von Oberbayern, DE-1992-HV-BS-B-14-46
                                </p>
                            </div>
                            <figcaption>
                                Franz Ludwig von Hornthal, 1822
                            </figcaption>
                        </figure>
                    </div>
                </Container.Row>
            </Container.Part>
        </Container.More>
    )
}

export default TextBlock5More
