import * as React from 'react'

const Page = () => {
    return (
        <>
            <p>
                	Diese Adresse an Se. Maj. den König ist – dem Vernehmen nach mit nahe an 2000 Unterschriften
                versehen – von Würzburg nach dem Orte ihrer Bestimmung abgegangen.
            </p>
        </>
    )
}

export default Page;